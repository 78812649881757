import React from 'react';
import Navigation from './Components/Navigation';
import ListOfColorChoices from './Components/ListOfColorChoices';
import logo from './logo.svg';
//import './Style.css';
import {Helmet} from "react-helmet";
import TwitchVideo from './Components/TwitchVideo';
import TwitchChat from './Components/TwitchChat';
import YoutubeChat from './Components/YoutubeChat';
import CustomColorPicker from './Components/CustomColorPicker';
import Pickr from '@simonwep/pickr/dist/pickr.es5.min';
import '@simonwep/pickr/dist/themes/nano.min.css';      // 'nano' theme

let defaultColors = { Red: "#FF0000", Orange: "#FFA500", Yellow: "#FFFF00", Green: "#00FF00", Blue: "#0000FF", Purple: "#3f50b5", Violet: "#EE82EE" };
let TwitchChannelName = "monstercat";
let YoutubeLiveStreamCode = "bQH6xDvmsQE";


function App() {

   
  return (
    <div className="App">
      <Helmet>

        <title> Control My Lights</title>
        <meta charset="utf-8" />
        <meta name="description" content="Todos!" />
          <meta name="theme-color" content="#008f68" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <Navigation id="TitleNavbar" SiteTitle="Control My Lights"
      Link1Point="#LiveControl" Link1Text="Live Control" 
      Link2Point="#LiveChat" Link2Text="Live Chat"
      Link3Point="#About" Link3Text="About"
      Link4Point="#Creator" Link4Text="Creator" />
            <CustomColorPicker /> 

          <div id="LiveControl" href="#LiveControl" className="container-fluid mt-5" style={{height:"100vh", background:'black !important'}} >
            
            <div className="d-flex justify-content-center  h-75">
              <div className="row">
                <div className="col">
                  <TwitchVideo ChannelName={TwitchChannelName} SiteSource={window.location.hostname} Autoplay={true} Title="Banner" Muted={false}></TwitchVideo>
                </div>
              </div>
            </div>

            <div className="row mt-5 mb-5">
              <div className="col">
                <div className="d-flex justify-content-center ">

                  <ListOfColorChoices colors="default" object={defaultColors} />
                </div>
              </div>
            </div>
          </div>
          {/* Second section: Chat data */}

          <div id="LiveChat" href="#LiveChat" className="container-fluid mt-5" style={{height:"120vh"}} >
            <div className="d-flex justify-content-center  h-75">
              <div className="row">
                <div className="col-lg" style={{height:"50vh"}}>
                <TwitchVideo ChannelName={TwitchChannelName} SiteSource={window.location.hostname} Autoplay={false} Title="Small" Muted={true}></TwitchVideo>

                </div>
                <div className="col-lg">
                <TwitchChat ChannelName={TwitchChannelName} siteSource={window.location.hostname}></TwitchChat>
                </div>
                <div className="col-lg">
                  <YoutubeChat LiveStreamCode={YoutubeLiveStreamCode} siteSource={window.location.hostname}></YoutubeChat>
                </div>
              </div>
            </div>
          </div>


         <footer> 
         Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
           
           </footer> 
      </div>


  );
}

export default App;
