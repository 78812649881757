
import React from 'react';


  class YoutubeChat extends React.Component {


    constructor(props) {
      super(props);
      this.state = {completeURL: "https://www.youtube.com/live_chat?is_popout=1&v="+props.LiveStreamCode+"&embed_domain="+props.siteSource};
    }
    componentDidMount(){

    }
    render() {
        return (
            <iframe src={this.state.completeURL} width="100%" height="100%"></iframe>
      );
    }
  }

  export default YoutubeChat;
