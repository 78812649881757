import React from 'react';

class ColorButton extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.divStyle = {borderColor:props.Hex, borderWidth: '5px'};
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit(event) {
      event.preventDefault();
    }
    async postData(url = '', data = {}) {
      // Default options are marked with *
      try{
        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          body: data // body data type must match "Content-Type" header
        });
        return response;
      }
      catch(error){
        return error;
      }
      }
    // So this gets my button's data once it's clicked, it's ran on click and the event or object data is sent to it. So then from here we could POST the data and disable the button for a bit.
    saveAndContinue = (e) => {
        e.preventDefault();
        //console.log(e.target);
        //console.log(e.target.value); //will give you the value continue

        var formdata = new FormData();
formdata.append("colorHex", e.target.value);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("/colorsubmit", requestOptions)
  .then(response => response.text())
  .catch(error => console.log('error', error));
        /*
        var formdata = new FormData();
        formdata.append("colorHex", e.target.value);
         this.postData('/colorsubmit', formdata).then(data => {console.log(data.ok);});
         */
         let target = document.getElementById(e.target.id);
         target.disabled = true;
          setTimeout(function() {
            target.disabled = false;
          }, 5000);

    }
    render() {
      return (
              <button type="button" className="btn btn-dark" id={this.props.Name} value={this.props.Hex} onClick={this.saveAndContinue} style={this.divStyle}>{this.props.Name}</button>
      );
    }
  }

  export default ColorButton;
