import React from 'react';
import ColorButton from './ColorButton';


  class ListOfColorChoices extends React.Component {
    // return JSON data from any file path (asynchronous)


    constructor(props) {
      super(props);
      this.state = {arrayOfColors: [], colorfromjson:[]};
      // load JSON data; then proceed
      if(props.colors === "default"){
        for ( const property in props.object) {
          //console.log("COMP MOUNTED");
          //console.log(`${property}: ${props.object[property]}`);
          this.state.arrayOfColors.push(<li className="nav-item px-2" key={property}><ColorButton  Name={property} Hex={props.object[property]} /></li>);
        }
      }

    }
    componentDidMount(){

    }
    render() {
        return (
          <ul class="nav colorNav" id="">
        
        {this.state.arrayOfColors}
      </ul>
      );
    }
  }

  export default ListOfColorChoices;
