import React from 'react';

class Navigation extends React.Component {
constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
  }
 
  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
    
  }
  
  render() {
      return (
        <nav id={this.props.id} className="navbar navbar-expand-lg navbar-light bg-light text-center sticky-md-top">
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
      <ul className="ml-auto mx-auto navbar-nav mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href={this.props.Link1Point}><h3>{this.props.Link1Text}</h3></a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href={this.props.Link2Point}><h3>{this.props.Link2Text}</h3></a>
        </li>
      </ul>
      <a className="navbar-brand" href={this.props.Link1Point}><h1>{this.props.SiteTitle}</h1></a>

      <ul className=" ml-auto mx-auto navbar-nav mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link " aria-current="page" href={this.props.Link3Point}><h3>{this.props.Link3Text}</h3></a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href={this.props.Link4Point}><h3>{this.props.Link4Text}</h3></a>
        </li>
      </ul>

    </div>
  </div>
</nav>
       
       /* <nav>
            <h1 classNameName="HiddenTitle" > {this.props.SiteTitle}</h1>
            <div classNameName="hamburger">
                <div classNameName="line"></div>
                <div classNameName="line"></div>
                <div classNameName="line"></div>
            </div>
            <ul classNameName="nav-links">
                <li><a href={this.props.Link1Point}>{this.props.Link1Text}</a></li>
                <li><a href={this.props.Link2Point}>{this.props.Link2Text}</a></li>
                <h1 classNameName="title"> {this.props.SiteTitle}</h1>
                <li><a href={this.props.Link3Point}>{this.props.Link3Text}</a></li>
                <li><a href={this.props.Link4Point}>{this.props.Link4Text}</a></li>
            </ul>
        </nav>

<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
<Navbar.Brand href="#home">{this.props.SiteTitle}</Navbar.Brand>

  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">

    <Nav classNameName="mr-auto">
      <Nav.Link href={this.props.Link1Point}>{this.props.Link1Text}</Nav.Link>
      <Nav.Link href={this.props.Link2Point}>{this.props.Link2Text}</Nav.Link>
      <Nav.Link href={this.props.Link3Point}>{this.props.Link3Text}</Nav.Link>
      <Nav.Link href={this.props.Link4Point}>{this.props.Link4Text}</Nav.Link>
    </Nav>
    <Nav>
      <Nav.Link href="#deets">More deets</Nav.Link>
      <Nav.Link eventKey={2} href="#memes">
        Dank memes
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
  
</Navbar>
*/
      );
    }
  }

  export default Navigation;
