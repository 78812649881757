import React from 'react';
import '@simonwep/pickr/dist/themes/nano.min.css';      // 'nano' theme

// Modern or es5 bundle (pay attention to the note below!)
import Pickr from '@simonwep/pickr/dist/pickr.es5.min';


class CustomColorPicker extends React.Component {
constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
  }
 
  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
    
  }
  componentDidMount(){
    const pickr = Pickr.create({
        el: '.pickr-container-button',
        theme: 'nano', // or 'monolith', or 'nano'
        position: 'top-middle',
        useAsButton: true,

        swatches: [
            'rgba(244, 67, 54, 1)',
            'rgba(233, 30, 99, 0.95)',
            'rgba(156, 39, 176, 0.9)',
            'rgba(103, 58, 183, 0.85)',
            'rgba(63, 81, 181, 0.8)',
            'rgba(33, 150, 243, 0.75)',
            'rgba(3, 169, 244, 0.7)',
            'rgba(0, 188, 212, 0.7)',
            'rgba(0, 150, 136, 0.75)',
            'rgba(76, 175, 80, 0.8)',
            'rgba(139, 195, 74, 0.85)',
            'rgba(205, 220, 57, 0.9)',
            'rgba(255, 235, 59, 0.95)',
            'rgba(255, 193, 7, 1)'
        ],
    
        components: {
    
            // Main components
            preview: true,
            opacity: true,
            hue: true,
    
            // Input / output Options
            interaction: {
                hex: false,
                rgba: true,
                hsla: false,
                hsva: false,
                cmyk: false,
                input: false,
                clear: false,
                save: true
            }
        }
    });
    document.getElementsByClassName("pcr-save")[0].value = "SEND TO LIGHTS";
    pickr.on('init', instance => {

        console.log('init', instance);



    }).on('hide', instance => {
        console.log('hide', instance);
    }).on('show', (color, instance) => {
        console.log('show', color, instance);
    }).on('save', (color, instance) => {
        pickr.hide();
        console.log('save', color, instance);
    }).on('clear', instance => {
        console.log('clear', instance);
    }).on('change', (color, instance) => {
        console.log('change', color, instance);
    }).on('changestop', instance => {
        console.log('changestop', instance);
    }).on('cancel', instance => {
        console.log('cancel', instance);
    }).on('swatchselect', (color, instance) => {
        console.log('swatchselect', color, instance);
    });
    }
    
  render() {
      return (
    <button class="pickr-container-button  btn  btn-lg pcr-button" > </button>
       
    
      );
    }
  }

  export default CustomColorPicker;
